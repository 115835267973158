<template>
  <div>
    <div class="tips" v-show="tipsShow">暂无数据</div>
    <div class="home-bg"></div>
    <div class="home-toast">
      <div class="close-btn" @click="closeToast">
        <img src="@/assets/image/home/toast/close.png" alt="" />
      </div>
      <!--详情页面--->
      <div class="article-details" v-if="checkInfo">
        <div class="caption flex-fs">
          <span @click="checkInfo = false">
            <img src="@/assets/image/home/toast/back.png" alt="" /> 小区业主动态
          </span>
        </div>
        <div class="info">
          <div class="title">标题名称</div>
          <div class="time">2024-05-03</div>
          <p>
            这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字这是公司简介文字
          </p>
        </div>
      </div>
      <div class="owner-details" v-if="checkOwner">
        <div class="caption flex-fs">
          <span @click="checkOwner = false">
            <img src="@/assets/image/home/toast/back.png" alt="" /> 一号楼
          </span>
        </div>
        <div class="labels flex-sb">
          业主状态:
          <div class="items flex-fs">
            <div
              class="item flex-fs"
              v-for="(item, index) in [
                '绑定',
                '投诉',
                '不满意',
                '报修',
                '活跃',
              ]"
              :key="index"
            >
              <div :class="'color' + (index + 1)"></div>
              {{ item }}
            </div>
          </div>
        </div>
        <div class="list">
          <div
            @click="handlCheckOwnerInfo"
            class="item flex-cc"
            :class="{ select: [0, 1, 2, 3, 10, 15].includes(index) }"
            v-for="(item, index) in 30"
            :key="index"
          >
            {{ 100 + index }}
            <div class="label color1" v-if="index < 2">绑定</div>
            <div class="label color2" v-if="index == 2">投诉</div>
            <div class="label color3" v-if="index == 3">不满意</div>
            <div class="label color4" v-if="index == 10">报修</div>
            <div class="label color5" v-if="index == 15">活跃</div>
          </div>
        </div>
      </div>
      <div class="owner-details-info" v-if="checkOwnerInfo">
        <div class="caption flex-fs">
          <span @click="handleBack">
            <img src="@/assets/image/home/toast/back.png" alt="" /> 业主档案
          </span>
        </div>
        <div class="title">一号楼</div>
        <div class="flex-sb">
          <div class="left">
            <div class="cap">业主信息</div>
            <div class="info">
              <div class="item">
                <div class="label">业主姓名:</div>
                皖北
              </div>
              <div class="item">
                <div class="label">身份证号:</div>
                440183422398123405
              </div>
              <div class="item">
                <div class="label">房产编号:</div>
                150018115651
              </div>
              <div class="item">
                <div class="label">房屋面积:</div>
                168 ㎡
              </div>
              <div class="item">
                <div class="label">联系方式:</div>
                13543467866
              </div>
            </div>
          </div>
          <div class="right">
            <div class="cap">业主状态</div>
            <div class="info">
              <div class="row flex-fs">
                <div>是否绑定: <span class="yes">是</span></div>
                <div>是否满意: <span class="red">否</span></div>
              </div>
              <div class="list" @click="handleTips">
                <div class="item flex-sb">
                  <div>报修: <span>1</span></div>
                  <div class="check">
                    查看详情
                    <img src="@/assets/image/construction/arrow.png" alt="" />
                  </div>
                </div>
                <div class="item flex-sb">
                  <div>投诉: <span>1</span></div>
                  <div class="check">
                    查看详情
                    <img src="@/assets/image/construction/arrow.png" alt="" />
                  </div>
                </div>
                <div class="item flex-sb">
                  <div>投票: <span>6</span></div>
                  <div class="check">
                    查看详情
                    <img src="@/assets/image/construction/arrow.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!checkOwner && !checkInfo && !checkOwnerInfo">
        <div class="tab-bar">
          <div
            class="tab-item"
            :class="index === selectInd ? 'select' + (index + 1) : ''"
            v-for="(item, index) in 5"
            :key="index"
            @click="swicthTab(index)"
          ></div>
        </div>
        <div class="tab-content">
          <!---小区简介-->
          <div class="tab-content-1 flex-fs" v-if="selectInd == 0">
            <div class="left">
              <div class="list">
                <div
                  class="item flex-fs"
                  v-for="(item, index) in houseList"
                  :key="index"
                >
                  <div class="label">{{ item.text + " " }}:</div>
                  <div class="value">{{ item.value }}</div>
                </div>
              </div>
            </div>
            <div class="right">
              <p>小区鸟瞰图</p>
              <div>
                <img src="@/assets/image/home/toast/nav1_2.png" alt="" />
              </div>
            </div>
          </div>
          <!---业主档案-->
          <div class="tab-content-2" v-if="selectInd == 1">
            <p class="caption">楼栋表</p>
            <div class="list">
              <div
                class="item flex-cc"
                v-for="(item, index) in 30"
                :key="index"
                @click="checkOwner = true"
              >
                {{ index + 1 }}号楼
              </div>
            </div>
          </div>
          <!---物业服务-->
          <div class="tab-content-3" v-if="selectInd == 2">
            <div class="sub-bar flex-fs">
              <div
                class="item"
                :class="{ select: subSelectInd == index }"
                v-for="(item, index) in subBarList"
                :key="index"
                @click="subSelectInd = index"
              >
                {{ item.text }}
              </div>
            </div>
            <div class="sub-content">
              <div class="sub1" v-if="subSelectInd == 0">
                山东巨野佳信物业管理有限公司成立于2014年10月17日，注册资本为300万元人民币，法定代表人为余灵文。公司地址位于巨野县人民路(麒麟大道)3888号11号楼。该公司属于房地产业，主要业务包括物业管理、园林绿化、市政工程、室内外装饰装修工程以及装饰材料的销售（危险化学品除外）。公司秉持“平凡的人做不平凡的事”的核心价值观，致力于为客户提供专业、细致、周到的一站式服务。公司重视员工的培训和个人发展，充分发挥每个员工的自主性与创造力，并为所取得的成绩而自豪。根据天眼查大数据分析，山东巨野佳信物业管理有限公司共对外投资了1家企业，并且拥有2个行政许可。
              </div>
              <div class="sub2" v-if="subSelectInd == 1">
                <div>
                  <img
                    v-for="(item, index) in zzList"
                    :key="index"
                    :src="item"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    v-for="(item, index) in zzList1"
                    :key="index"
                    :src="item"
                    alt=""
                  />
                </div>
              </div>
              <div class="sub3 flex-sb" v-if="subSelectInd == 2">
                <div v-for="(item, index) in servieList" :key="index">
                  <img :src="item.img" alt="" />
                  <p class="text">{{item.text}}</p>
                  <p class="name">{{item.name}}</p>
                  <p class="phone">{{item.phone}}</p>
                </div>
              </div>
              <div class="sub4" v-if="subSelectInd == 3">
                <img src="@/assets/image/home/toast/biaozhun.png" alt="" />
              </div>
            </div>
          </div>
          <!---业委会-->
          <div class="tab-content-4" v-if="selectInd == 3">
            <div class="left">
              <div class="caption">成员介绍</div>
              <div class="info">
                <div class="item" v-for="(item, index) in committee" :key="index">
                  <div class="img">
                    <img :src="item.img" alt="" />
                  </div>
                  <div class="dec">
                    <div><span>姓名:</span>{{item.text}}</div>
                    <div><span>职务:</span>{{item.name}}</div>
                    <div><span>电话:</span>{{item.phone}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="caption">业委会动态</div>
              <div class="list">
                <div class="item" v-for="(item, index) in 4" :key="index">
                  <div class="left">
                    <div class="title">
                      共绘美好家园新篇章——业委会筹备工作恳谈会圆满召开
                    </div>
                    <div class="time">2024-05-01</div>
                  </div>
                  <div class="img">
                    <img src="@/assets/image/home/toast/hot.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!---动态信息-->
          <div class="tab-content-3 tab-content-5" v-if="selectInd == 4">
            <div class="sub-bar flex-fs">
              <div
                class="item"
                :class="{ select: subInfoSelectInd == index }"
                v-for="(item, index) in ['小区业主动态', '汇总分析']"
                :key="index"
                @click="subInfoSelectInd = index"
              >
                {{ item }}
              </div>
            </div>
            <div class="sub-content">
              <div v-if="subInfoSelectInd == 0">
                <div class="list">
                  <div
                    class="item flex-sb"
                    v-for="(item, index) in 15"
                    :key="index"
                    @click="checkInfo = true"
                  >
                    <div class="flex-fs">
                      <div class="time">2024-05-02</div>
                      <div>
                        物业公司维修小区道路物业公司维修小区道路物业公司维修
                      </div>
                    </div>
                    <div class="check">
                      查看详情
                      <img src="@/assets/image/construction/arrow.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeToast",
  data() {
    return {
      tipsShow: false,
      checkInfo: false,
      checkOwnerInfo: false,
      checkOwner: false,
      selectInd: 0,
      subInfoSelectInd: 0,
      servieList:[
        {img:require("@/assets/image/home/toast/s1.png"),text:'项目经理',name:'李晓光',phone:'18292452044'},
        {img:require("@/assets/image/home/toast/s2.png"),text:'安防主管',name:'叶聪',phone:'18629420686'},
        {img:require("@/assets/image/home/toast/s3.png"),text:'环境主管',name:'王春燕',phone:'18700883054'},
        {img:require("@/assets/image/home/toast/s4.png"),text:'工程主管',name:'周科文',phone:'18092677002'},
        {img:require("@/assets/image/home/toast/s5.png"),text:'客服主管',name:'刘敏',phone:'029-65677186'},
      ],
      committee:[
        {img:require("@/assets/image/home/toast/y2.png"),text:'主持全面工作',name:'王国强',phone:'13214578957'},
        {img:require("@/assets/image/home/toast/y1.png"),text:'文书档案管理',name:'曹巧英',phone:'15845672145'},
        {img:require("@/assets/image/home/toast/y1.png"),text:'环境主管',name:'王国强',phone:'13214578957'},
        {img:require("@/assets/image/home/toast/y2.png"),text:'工程主管',name:'曹巧英',phone:'15845672145'},
      
      ],
      zzList: [
        require("@/assets/image/home/zz1.png"),
        require("@/assets/image/home/zz2.png"),
      ],
      zzList1: [
        require("@/assets/image/home/1zz.png"),
        require("@/assets/image/home/2zz.png"),
        require("@/assets/image/home/3zz.png"),
        require("@/assets/image/home/4zz.png"),
      ],
      houseList: [
        {
          text: "项目名称",
          value: "XXX州府",
        },
        {
          text: "占用面积",
          value: "48681 ㎡",
        },
        {
          text: "绿化面积",
          value: "411 ㎡",
        },
        {
          text: "总户数",
          value: "486户",
        },
        {
          text: "开发单位",
          value: "巨野县XXXX开发公司",
        },
        {
          text: "物业服务单位",
          value: "巨野县XXXX单位",
        },
      ],
      subSelectInd: 0,
      subBarList: [
        { text: "公司简介" },
        { text: "资质证书" },
        { text: "服务人员" },
        { text: "收费标准" },
        { text: "服务内容" },
      ],
    };
  },
  methods: {
    closeToast() {
      this.$emit("closeToast");
      this.checkInfo = false;
    },
    handleTips() {
      this.tipsShow = true;
      setTimeout(() => {
        this.tipsShow = false;
      }, 2000);
    },
    swicthTab(val) {
      this.selectInd = val;
    },
    handlCheckOwnerInfo() {
      this.checkOwnerInfo = true;
      this.checkOwner = false;
    },
    handleBack() {
      this.checkOwnerInfo = false;
      this.checkOwner = true;
    },
  },
};
</script>
<style scoped lang="less">
.tips {
  transition: 1s;
  color: #fff;
  opacity: 0.8;
  background: #000;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-60px);
  z-index: 66336;
}
.home-bg {
  position: fixed;
  top: 250px;
  left: 430px;
  z-index: 6666;
  width: 780px;
  height: 492px;
  filter: blur(10px); /* 应用模糊效果 */
  background: rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 20px 30px 0px rgba(29, 157, 243, 0.2),
    inset 0px -20px 30px 0px rgba(29, 157, 243, 0.2);
}
.home-toast {
  position: fixed;
  top: 250px;
  left: 430px;
  z-index: 66666;
  width: 780px;
  height: 492px;
  background: rgba(0, 0, 0, 0.3);

  box-shadow: inset 0px 20px 30px 0px rgba(29, 157, 243, 0.2),
    inset 0px -20px 30px 0px rgba(29, 157, 243, 0.2);
  border-bottom: 6px solid #1d9df3;
  border-top: 6px solid #1d9df3;
  .close-btn {
    position: absolute;
    top: -30px;
    right: -30px;
    cursor: pointer;
    img {
      width: 24px;
    }
  }
  .tab-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 40px 0 40px;

    .tab-item {
      width: 140px;
      height: 36px;
      background-size: 100% 100%;
      cursor: pointer;
      &:nth-child(1) {
        background-image: url(../assets/image/home/toast/tnav1.png);
      }
      &:nth-child(2) {
        background-image: url(../assets/image/home/toast/tnav2.png);
      }
      &:nth-child(3) {
        background-image: url(../assets/image/home/toast/tnav3.png);
      }
      &:nth-child(4) {
        background-image: url(../assets/image/home/toast/tnav4.png);
      }
      &:nth-child(5) {
        background-image: url(../assets/image/home/toast/tnav5.png);
      }
      &:nth-child(1):hover {
        background-image: url(../assets/image/home/toast/tnav1_1.png);
      }
      &:nth-child(2):hover {
        background-image: url(../assets/image/home/toast/tnav2_1.png);
      }
      &:nth-child(3):hover {
        background-image: url(../assets/image/home/toast/tnav3_1.png);
      }
      &:nth-child(4):hover {
        background-image: url(../assets/image/home/toast/tnav4_1.png);
      }
      &:nth-child(5):hover {
        background-image: url(../assets/image/home/toast/tnav5_1.png);
      }
      &.select1 {
        background-image: url(../assets/image/home/toast/tnav1_1.png) !important;
      }
      &.select2 {
        background-image: url(../assets/image/home/toast/tnav2_1.png) !important;
      }
      &.select3 {
        background-image: url(../assets/image/home/toast/tnav3_1.png) !important;
      }
      &.select4 {
        background-image: url(../assets/image/home/toast/tnav4_1.png) !important;
      }
      &.select5 {
        background-image: url(../assets/image/home/toast/tnav5_1.png) !important;
      }
    }
  }
  /********/
  .tab-content {
    &-1 {
      margin-top: 80px;
      height: 300px;
      .left {
        width: 362px;
        .list {
          padding-left: 40px;
          .item {
            margin-bottom: 15px;
            .label {
              color: #bce1f2;
              font-size: 14px;
            }
            .value {
              padding-left: 3px;
              font-size: 16px;
              color: #fff;
            }
          }
        }
      }
      .right {
        text-align: center;
        p {
          font-size: 16px;
          margin-bottom: 12px;
          color: #fff;
        }
        img {
          width: 300px;
          height: 300px;
          margin-bottom: 12px;
        }
      }
    }
    //////业主档案//////
    &-2 {
      padding-top: 40px;
      .caption {
        padding-left: 40px;
        font-size: 16px;
        color: #bce1f2;
        margin-bottom: 20px;
      }
      .list {
        height: 310px;
        overflow: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 40px;
        &::-webkit-scrollbar {
          display: none; /* 针对WebKit浏览器隐藏滚动条 */
        }

        -ms-overflow-style: none; /* 针对IE和Edge隐藏滚动条 */

        .item {
          cursor: pointer;
          margin-bottom: 20px;
          width: 124px;
          height: 62px;
          color: #fff;
          font-size: 14px;
          background: rgba(29, 157, 243, 0.5);
        }
      }
    }
    //////物业服务//////
    &-3 {
      .sub-bar {
        padding-top: 40px;
        margin-left: 8px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.7);
        .item {
          padding-left: 32px;
          font-size: 16px;
          color: #fff;
          cursor: pointer;
          position: relative;
          &.select {
            color: #1d9df3;
            &::after {
              content: "";
              position: absolute;
              width: 40px;
              height: 4px;
              background: #1d9df3;
              bottom: -10px;
              left: 50%;
              transform: translate(-4px);
            }
          }
          &:hover {
            color: #1d9df3;
            &::after {
              content: "";
              position: absolute;
              width: 40px;
              height: 4px;
              background: #1d9df3;
              bottom: -10px;
              left: 50%;
              transform: translate(-4px);
            }
          }
        }
      }
      .sub-content {
        .sub1 {
          padding-left: 40px;
          padding-top: 20px;
          color: #fff;
          font-size: 14px;
          line-height: 22px;
          opacity: 0.7;
        }
        .sub2 {
          padding: 40px;
          img {
            width: 124px;
            margin-right: 20px;
          }

          div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            &:nth-child(2) {
              img {
                height: 170px;
              }
            }
          }
        }
        .sub3 {
          padding: 20px 40px;
          .text{
            color: #BCE1F2;
          }
          img {
            width: 124px;
            height: 165px;
          }
          p {
            text-align: center;
            font-size: 14px;
            color: #fff;
            padding-top: 8px;
          }
        }
        .sub4{
          text-align: center;
          padding:20px 40px;
          img{
            width: 75%;
          }
        }
      }
    }
    //////业委会//////
    &-4 {
      padding: 40px 40px 0 40px;
      display: flex;
      justify-content: space-between;
      .caption {
        color: #bce1f2;
        font-size: 16px;
        margin-bottom: 20px;
      }
      .left {
        .info {
          padding: 0 20px;
          width: 330px;
          height: 292px;
          background: rgba(29, 157, 243, 0.2);
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          overflow: auto;
          &::-webkit-scrollbar {
            display: none; /* 针对WebKit浏览器隐藏滚动条 */
          }

          -ms-overflow-style: none; /* 针对IE和Edge隐藏滚动条 */

          .item {
            position: relative;
            margin-top: 60px;
            width: 140px;
            height: 174px;
            padding-top: 68px;
            background: rgba(29, 157, 243, 0.3);
            img {
              position: absolute;
              left: 50%;
              transform: translate(-50px);
              width: 100px;
              height: 124px;
              top: -50px;
            }
            .dec {
              padding-top: 20px;
              color: #fff;
              padding-left: 12px;
              font-size: 12px;
              div {
                margin-bottom: 8px;
              }
              span {
                padding-right: 4px;
                opacity: 0.7;
              }
            }
          }
        }
      }
      .right {
        .list {
          padding: 20px;
          width: 330px;
          height: 292px;
          background: rgba(29, 157, 243, 0.2);
          overflow: auto;
          &::-webkit-scrollbar {
            display: none; /* 针对WebKit浏览器隐藏滚动条 */
          }

          -ms-overflow-style: none; /* 针对IE和Edge隐藏滚动条 */

          .item {
            background: rgba(29, 157, 243, 0.3);
            padding-left: 12px;
            display: flex;
            margin-bottom: 10px;
            justify-content: space-between;
            .left {
              color: #fff;
              .title {
                font-size: 14px;
                padding-top: 12px;
                padding-bottom: 6px;
                padding-right: 4px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                white-space: normal;
                height: 50px;
              }

              .time {
                opacity: 0.7;
                font-size: 12px;
              }
            }
            .img {
              padding: 4px;
              img {
                width: 85px;
                height: 64px;
              }
            }
          }
        }
      }
    }
    ///动态信息//
    &-5 {
      .list {
        padding: 20px 40px 0;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none; /* 针对WebKit浏览器隐藏滚动条 */
        }
        height: 320px;
        -ms-overflow-style: none; /* 针对IE和Edge隐藏滚动条 */

        .item {
          font-size: 14px;
          margin-bottom: 16px;
          cursor: pointer;
          color: #fff;
          .time {
            color: #1d9df3;
            padding-right: 20px;
          }
          .check {
            color: #00fbff;

            img {
              width: 14px;
              height: 14px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
.article-details {
  padding: 40px;
  .caption {
    cursor: pointer;
    color: #00fbff;
    font-size: 16px;
    margin-bottom: 20px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  .info {
    text-align: center;
    color: #fff;
    .title {
      font-size: 20px;
    }
    .time {
      font-size: 14px;
      padding: 8px 0 20px;
      color: #1d9df3;
    }
    p {
      font-size: 14px;
      text-align: left;
      line-height: 24px;
    }
  }
}
.owner-details-info {
  padding: 40px;
  .caption {
    cursor: pointer;
    color: #00fbff;
    font-size: 16px;
    margin-bottom: 20px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  .title {
    color: #1d9df3;
    font-size: 24px;
    padding: 20px 0;
    text-align: center;
  }

  .left,
  .right {
    .cap {
      color: #bce1f2;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .info {
      width: 330px;
      height: 208px;
      padding-left: 12px;
      padding-top: 22px;
      background: rgba(0, 0, 0, 0.5);
      box-shadow: inset 0px 20px 30px 0px rgba(29, 157, 243, 0.2),
        inset 0px -20px 30px 0px rgba(29, 157, 243, 0.2);
    }
  }
  .left {
    font-size: 16px;
    color: #fff;

    .item {
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .label {
      color: #bce1f2;
      font-size: 14px;
      padding-right: 4px;
    }
  }
  .right {
    .row {
      margin-top: 5px;
      margin-bottom: 5px;
      div {
        font-size: 14px;
        color: #bce1f2;
        margin-right: 40px;
        .yes {
          color: #00e291;
        }
        .red {
          color: #ff7b7b;
        }
      }
    }
    .item {
      border-bottom: 1px solid rgba(29, 157, 243, 0.2);
      padding: 12px 0;
      margin-right: 12px;
      color: #bce1f2;
      .check {
        color: #00fbff;
        cursor: pointer;
      }
      font-size: 14px;
      span {
        color: #1d9df3;
        font-size: 16px;
      }
      img {
        width: 14px;
        height: 14px;
        margin-left: 4px;
      }
    }
  }
}
.owner-details {
  padding: 40px;
  .caption {
    cursor: pointer;
    color: #00fbff;
    font-size: 16px;
    margin-bottom: 20px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  .labels {
    color: #1d9df3;
    font-size: 16px;
    margin-bottom: 12px;
    .items {
      font-size: 14px;
      color: #fff;
      .item {
        margin-left: 20px;
        div {
          width: 12px;
          height: 4px;
          margin-right: 8px;
        }
      }
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 350px;
    &::-webkit-scrollbar {
      display: none; /* 针对WebKit浏览器隐藏滚动条 */
    }

    -ms-overflow-style: none; /* 针对IE和Edge隐藏滚动条 */

    overflow: auto;
    .item {
      cursor: pointer;
      position: relative;
      width: 124px;
      margin-bottom: 20px;
      height: 62px;
      background: rgba(29, 157, 243, 0.5);
      border-radius: 0px 0px 0px 0px;
      color: #fff;
      font-size: 14px;
      &.select {
        padding-bottom: 20px;
      }
      .label {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        height: 20px;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .color1 {
    background: #1d9df3;
  }
  .color2 {
    background: #ff7b7b;
  }
  .color3 {
    background: #9664ff;
  }
  .color4 {
    background: #ff9356;
  }
  .color5 {
    background: #00e291;
  }
}
</style>
