<template>
  <div class="container">
    <div class="content">
      <div class="content-left">
        <div class="session left1">
          <div class="item" v-for="(item, index) in left1" :key="index">
            <div class="num flex-cc">{{ item.num }} <span>件</span></div>
            <div class="text flex-cc">
              {{ item.text }}
            </div>
          </div>
          <div class="chart-text">
            <div>
              已处理
              <div class="span">154 <span>件</span></div>
            </div>
            <div>
              逾期
              <div class="span">2 <span>件</span></div>
            </div>
          </div>
        </div>

        <div class="session left2">
          <div>
            <div class="left2-left">
              <div class="num">16,143 <span>件</span></div>
              <div class="text">总设备数量</div>
            </div>
            <div class="left2-right">
              <div class="zx">
                <div class="text">在线</div>
                <div class="num">12,543 <span>台</span></div>
                <div class="icon">
                  <img src="@/assets/image/construction/left2_2.png" alt="" />
                </div>
              </div>
              <div class="lx">
                <div class="text">离线</div>
                <div class="num">43 <span>台</span></div>
                <div class="icon">
                  <img src="@/assets/image/construction/left2_3.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="session left3">
          <div class="item">
            <div class="num">226 <span>人/次</span></div>
            <div class="text">总体小区巡更人次</div>
          </div>
          <div class="item">
            <div class="num">2 <span>人/次</span></div>
            <div class="text">巡更异常人次</div>
          </div>
        </div>
      </div>
      <div class="content-middle">
        <div class="session middle1">
          <div class="tabs">
            <div
              @click="tabSwitch(1)"
              class="tab-item"
              :class="{ active: tabInd == 1 }"
            >
              高空抛物
            </div>
            <div
              @click="tabSwitch(2)"
              class="tab-item"
              :class="{ active: tabInd == 2 }"
            >
              电车入梯预警
            </div>
          </div>

          <div class="tab-content">
            <img
              v-if="tabInd == 1"
              src="@/assets/image/construction/middle1_1.png"
              alt=""
            />
            <img
              v-else
              src="@/assets/image/construction/middle1_2.png"
              alt=""
            />
          </div>
        </div>
        <div class="session middle2">
          <div class="item" v-for="(item, index) in 3" :key="index">
            <div class="img">
              <img src="@/assets/image/construction/middle1_1.png" alt="" />
            </div>
            <div class="dec">
              <div class="title">
                超燃！我国将进入“三航母时代”！
                <span
                  >详情 <img src="@/assets/image/construction/arrow.png" alt=""
                /></span>
              </div>
              <div class="info">
                事故路段：广州越秀区路段
                <span>预报时间: 2024-05-03 13:43:11</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="session right1">
          <div id="chart"></div>
          <div class="chart">
            <div>
              <p>总任务数</p>
              <div class="num">315 <span>个</span></div>
            </div>
            <div>
              <p>完成</p>
              <div class="num">316 <span>个</span></div>
            </div>
            <div class="chart-data">
              <p>完成率</p>
              <div class="num">95 <span>%</span></div>
            </div>
          </div>
          <div class="flex-fs">
            <div class="item" v-for="(item, index) in right1" :key="index">
              <div class="text flex-cc">
                {{ item.text }}
              </div>
              <div class="num flex-cc">{{ item.num }} <span>台</span></div>
            </div>
          </div>
        </div>
        <div class="session right2">
          <div class="item" v-for="(item, index) in right2" :key="index">
            <div class="text">{{ item.text }}</div>
            <div class="line">
              <div
                class="line-bar"
                :style="{ width: item.value * 3 + 'px' }"
              ></div>
              <div class="value">{{ item.value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "construction",
  components: {},
  data() {
    return {
      tabInd: 1,
      left1: [
        {
          num: "245",
          text: "总报修",
        },
      ],
      right1: [
        {
          num: "232",
          text: "设备正常数量",
        },
        {
          num: "22",
          text: "设备异常数量",
        },
      ],
      left2: [
        {
          text: "银河",
          num: "11",
          tag: "家",
        },
        {
          text: "小区",
          num: "57",
          tag: "个",
        },
        {
          text: "楼房",
          num: "463",
          tag: "栋",
        },
        {
          text: "户数",
          num: "5214",
          tag: "户",
        },
      ],
      right2: [
        {
          text: "幸福里小区电车入梯",
          value: 75,
        },
        {
          text: "小区电车入梯",
          value: 69,
        },
        {
          text: "文景新城道路",
          value: 65,
        },
        {
          text: "越秀街道",
          value: 57,
        },
        {
          text: "高发事件名称",
          value: 52,
        },
        {
          text: "高发事件名称",
          value: 42,
        },
        {
          text: "高发事件名称",
          value: 35,
        },
        {
          text: "高发事件名称",
          value: 30,
        },
        {
          text: "高发事件名称",
          value: 25,
        },
        {
          text: "高发事件名称",
          value: 20,
        },
        {
          text: "高发事件名称",
          value: 17,
        },
        {
          text: "高发事件名称",
          value: 14,
        },
        {
          text: "高发事件名称",
          value: 10,
        },
        {
          text: "高发事件名称",
          value:7,
        },
        {
          text: "高发事件名称",
          value: 5,
        },
      ],
    };
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      const chart = this.$echarts.init(document.getElementById("chart"));

      chart.setOption({
        series: [
          {
            name: "投票",
            type: "pie",
            radius: ["50%", "75%"],
            center: ["50%", "60%"],
            data: [
              { value: 1400, name: "1400次" },
              { value: 5000, name: "5000次" },
            ],
            itemStyle: {
              color: function (params) {
                // 定义一个颜色数组
                var colors = ["#233379", "#1D9DF3"];
                // 返回每个扇区的颜色，可以按照其他逻辑设置颜色
                return colors[params.dataIndex % colors.length];
              },
            },
            label: {
              show: false,
              position: "center",
            },
          },
        ],
      });
    },
    tabSwitch(val) {
      this.tabInd = val;
    },
  },
};
</script>

<style lang="less" scoped>
.container {

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-left {
      width: 420px;
      margin: 0 20px;
      .session {
        height: 300px;
        margin-bottom: 20px;
      }
      .left1 {
        background-image: url(../assets/image/construction/left1.png);
        background-size: 100% 100%;
        padding-top: 85px;

        .item {
          margin: 0 auto 20px;
          width: 180px;
          height: 102px;

          color: #bce1f2;
          .num {
            background: #1d9df3;
            height: 70px;
            color: #ffffff;
            font-size: 16px;

            font-size: 24px;
            font-weight: bold;
            span {
              opacity: 0.7;
              font-size: 14px;
              padding-left: 5px;
              font-weight: 500;
              margin-bottom: -7px;
            }
          }
          .text {
            height: 32px;
            background: rgba(29, 157, 243, 0.1);
            font-size: 14px;
          }
        }
        .chart-text {
          display: flex;
          justify-content: space-around;
          align-items: center;
          & > div {
            width: 180px;
            height: 44px;
            margin-bottom: 12px;
            color: #bce1f2;
            font-size: 12px;
            background-image: url(../assets/image/construction/left1_1.png);
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px;
            .span {
              font-size: 16px;
              color: #00e291;
              span {
                font-size: 12px;
                opacity: 0.7;
              }
            }
            &:nth-child(2) {
              background-image: url(../assets/image/construction/left1_2.png);
              .span {
                color: #ff9356;
              }
            }
          }
        }
      }
      .left2 {
        background-image: url(../assets/image/construction/left2.png);
        background-size: 100% 100%;
        padding-top: 36px;
        & > div {
          padding-top: 36px;
          height: 264px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          text-align: center;
        }
        .left2-left {
          background-image: url(../assets/image/construction/left2_1.png);
          background-size: 100% 100%;
          width: 180px;
          height: 187px;
          .num {
            color: #00fbff;
            font-size: 24px;
            padding-top: 58px;
            padding-bottom: 8px;
            span {
              font-size: 14px;
              opacity: 0.7;
            }
          }
          .text {
            font-size: 14px;
            color: #fff;
            opacity: 0.8;
          }
        }
        .left2-right {
          width: 180px;
          text-align: left;

          & > div {
            position: relative;
            height: 102px;
            margin-bottom: 20px;
            width: 180px;
            padding-left: 12px;
          }
          .zx {
            background: rgba(0, 226, 145, 0.1);
            color: #00e291;
          }
          .lx {
            background: rgba(255, 123, 123, 0.1);
            color: #ff7b7b;
          }
          .num {
            font-size: 20px;
            span {
              opacity: 0.7;
              font-size: 14px;
            }
          }
          .text {
            color: #bce1f2;
            font-size: 14px;
            padding-top: 22px;
            padding-bottom: 10px;
          }
          .icon {
            position: absolute;
            top: 20px;
            right: 12px;
            img {
              width: 48px;
              height: 48px;
            }
          }
        }
      }
      .left3 {
        background-image: url(../assets/image/construction/left3.png);
        background-size: 100% 100%;
        position: relative;
        display: flex;
        justify-content: space-around;
        padding-top: 72px;
        .item {
          width: 180px;
          height: 191px;
          text-align: center;
          &:nth-child(1) {
            background-image: url(../assets/image/construction/left3_1.png);
          }
          &:nth-child(2) {
            background-image: url(../assets/image/construction/left3_2.png);
          }
          background-size: 100% 100%;
          color: #fff;
          font-size: 14px;
          .num {
            font-size: 32px;
            font-weight: bold;
            padding-top: 35px;
            padding-bottom: 50px;
          }
          span {
            opacity: 0.7;
            font-size: 14px;
            font-weight: 500;
          }
          .text {
            color: #bce1f2;
          }
        }
      }
    }
    &-middle {
      width: 1000px;
      .session {
        margin-bottom: 20px;
      }
      .middle1 {
        background-image: url(../assets/image/construction/middle1.png);
        background-size: 100% 100%;

        height: 620px;
        .tabs {
          padding-top: 56px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #218eff;
          font-size: 14px;
          padding-left: 20px;
          .tab-item {
            margin-right: 40px;
            cursor: pointer;
            &.active {
              position: relative;
              color: #00fbff;
              &::after {
                content: "";
                position: absolute;
                width: 40px;
                height: 4px;
                background: #00fbff;
                bottom: -8px;
                left: 50%;
                transform: translate(-20px);
              }
            }
          }
        }
        .tab-content {
          padding-top: 28px;
          padding-left: 45px;
          padding-right: 123px;
          img {
            width: 100%;
          }
        }
      }
      .middle2 {
        height: 300px;
        background-image: url(../assets/image/construction/middle2.png);
        background-size: 100% 100%;
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none; /* 针对WebKit浏览器隐藏滚动条 */
        }

        -ms-overflow-style: none; /* 针对IE和Edge隐藏滚动条 */
       
       .item {
          margin-bottom: 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 78px;
          background: rgba(29, 157, 243, 0.1);
          .img {
            width: 125px;
            height: 70px;
            overflow: hidden;
            margin-right: 12px;
            img {
              width: 100%;
            }
          }
          .dec {
            .title {
              color: #ff7b7b;
              font-size: 16px;
              margin-bottom: 8px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 800px;
              img {
                width: 14px;
                height: 14px;
                vertical-align: middle;
              }
              span {
                cursor: pointer;
                font-size: 14px;
                color: #00fbff;
              }
            }
            .info {
              color: #bce1f2;
              span {
                padding-left: 28px;
              }
            }
          }
        }
      }
    }
    &-right {
      width: 420px;
      margin: 0 20px;
      .session {
        margin-bottom: 20px;
        height: 328px;
      }
      .right1 {
        background-image: url(../assets/image/construction/right1.png);
        background-size: 100% 100%;
        position: relative;
        padding-top: 20px;
        .flex-fs {
          display: flex;
          padding-top: 18px;
          justify-content: flex-start;
          align-items: center;
        }
        #chart {
          height: 160px;
        }
        .chart {
          height: 160px;
          position: absolute;
          top: 20px;
          left: 0;
          right: 0;
          padding: 0 44px 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > div {
            p {
              padding-top: 82px;
              font-size: 14px;
              color: #bce1f2;
              padding-bottom: 12px;
            }
            .num {
              color: #00fbff;
              font-size: 24px;
              span {
                opacity: 0.7;
                font-size: 14px;
              }
            }
          }
          .chart-data {
            position: absolute;
            top: 10px;
            left: 46%;
            p {
              padding-bottom: 4px;
              font-size: 12px;
              padding-top: 62px;
            }
            .num {
              color: #fff;
            }
          }
        }
        .item {
          margin-left: 20px;
          width: 180px;
          height: 102px;

          color: #bce1f2;
          .num {
            background: rgba(0, 226, 145, 0.1);
            height: 72px;
            color: #ffffff;
            font-size: 16px;

            font-size: 24px;
            font-weight: bold;
            span {
              opacity: 0.7;
              font-size: 14px;
              padding-left: 5px;
              font-weight: 500;
              margin-bottom: -7px;
            }
          }
          .text {
            height: 40px;
            color: #fff;
            font-size: 14px;
          }
          &:nth-child(1) {
            .text {
              background: #00e291;
            }
          }
          &:nth-child(2) {
            .text {
              background: #ff7b7b;
            }
          }
        }
      }
      .right2 {
        background-image: url(../assets/image/construction/right2.png);
        background-size: 100% 100%;
        height: 592px;
        padding: 56px 20px 0;
        font-size: 14px;
        color: #fff;
        .item {
          margin-bottom: 16px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .text {
            opacity: 0.7;
            width: 134px;
            text-align: left;
          }
          .line {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .line-bar {
              height: 16px;
              background: #1d9df3;
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
            }
            .value {
              padding-left: 5px;
              opacity: 0.8;
            }
          }
          &:nth-child(1) {
            .text {
              color: #FFEB36;
            }
            .line-bar {
              background: #FFEB36;
            }
            .value {
              color: #FFEB36;
            }
          }
          &:nth-child(2) {
            .text {
              color: #BCE1F2;
            }
            .line-bar {
              background: #BCE1F2;
            }
            .value {
              color: #BCE1F2;
            }
          }
          &:nth-child(3) {
            .text {
              color: #FF9356;
            }
            .line-bar {
              background: #FF9356;
            }
            .value {
              color: #FF9356;
            }
          }
        }
      }
    }
  }
}
</style>
