<template>
  <div class="container">
    <div class="content">
      <div class="content-left">
        <div class="session left1">
          <div class="item" v-for="(item, index) in left1" :key="index">
            <div class="num flex-cc" :class="{ other: index > 1 }">
              {{ item.num }} <span>{{ index < 2 ? "笔" : "万元" }}</span>
            </div>
            <div class="text flex-cc" :class="{ other: index > 1 }">
              {{ item.text }}
            </div>
          </div>
        </div>
        <div class="session left2">
          <div class="item" v-for="(item, index) in left2" :key="index">
            <div class="text">{{ item.text }}</div>
            <div class="num">
              {{ item.num }} <span>{{ item.tag }}</span>
            </div>
            <div class="icon">
              <img :src="item.icon" alt="" />
            </div>
          </div>
        </div>
        <div class="session left3">
          <div class="chart" id="myChart"></div>
          <div class="data">
            <p>累计入账金额</p>
            <div>2,345 <span>万元</span></div>
          </div>
          <div class="chart-text">
            <div>
              参与人数
              <div class="span">222 <span>人</span></div>
            </div>
            <div>
              通过率
              <div class="span">12.5 <span>%</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-middle">
        <div class="session middle1">
          <div class="item" v-for="(item, index) in middle1" :key="index">
            <div class="num">
              {{ item.num }} <span>{{ item.tag }}</span>
            </div>
            <div class="text">
              {{ item.text }}
            </div>
            <div class="icon"></div>
          </div>
        </div>
        <div class="session middle2" id="middle2"></div>
        <div class="session middle3" id="middle3"></div>
      </div>
      <div class="content-right">
        <div class="session right1">
          <div class="other">
            <div class="num flex-cc">234,329 <span>个</span></div>
            <div class="text">缴存</div>
          </div>
          <div class="items">
            <div class="item" v-for="(item, index) in right1" :key="index">
              <div class="num" :class="'num' + index">
                {{ item.num }} <span>个</span>
              </div>
              <div class="text">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
        <div class="session right2" id="right2"></div>
        <div class="session right3" id="right3"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "maintenance",
  components: {},
  data() {
    return {
      left1: [
        {
          num: "89,439",
          text: "归集笔数",
        },
        {
          num: "24,734",
          text: "使用笔数",
        },
        {
          num: "1,543,234",
          text: "归集金额",
        },
        {
          num: "66,327",
          text: "使用金额",
        },
      ],
      left2: [
        {
          text: "银河",
          num: "11",
          tag: "家",
          icon: require("@/assets/image/maintain/left2_1.png"),
        },
        {
          text: "小区",
          num: "57",
          tag: "个",
          icon: require("@/assets/image/maintain/left2_2.png"),
        },
        {
          text: "楼房",
          num: "463",
          tag: "栋",
          icon: require("@/assets/image/maintain/left2_3.png"),
        },
        {
          text: "户数",
          num: "5214",
          tag: "户",
          icon: require("@/assets/image/maintain/left2_4.png"),
        },
      ],
      middle1: [
        {
          num: "23,456",
          text: "一般使用笔数",
          tag: "笔",
        },
        {
          num: "214,536",
          text: "一般使用金额",
          tag: "万元",
        },
        {
          num: "6,145",
          text: "紧急使用笔数",
          tag: "笔",
        },
        {
          num: "76,342",
          text: "紧急使用金额",
          tag: "万元",
        },
      ],
      right1: [
        {
          num: "87,234",
          text: "开户",
        },
        {
          num: "56,785",
          text: "过户",
        },
        {
          num: "34,235",
          text: "申请",
        },
        {
          num: "12,574",
          text: "使用",
        },
      ],
    };
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById("myChart"));
      const right2 = this.$echarts.init(document.getElementById("right2"));
      const right3 = this.$echarts.init(document.getElementById("right3"));
      const middle2 = this.$echarts.init(document.getElementById("middle2"));
      const middle3 = this.$echarts.init(document.getElementById("middle3"));
      myChart.setOption({
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "投票",
            type: "pie",
            radius: ["42%", "60%"],
            center: ["35%", "55%"],
            data: [
              { value: 1400, name: "1400次" },
              { value: 5000, name: "5000次" },
            ],
            itemStyle: {
              color: function (params) {
                // 定义一个颜色数组
                var colors = ["#00E291", "#FF7B7B"];
                // 返回每个扇区的颜色，可以按照其他逻辑设置颜色
                return colors[params.dataIndex % colors.length];
              },
            },
          },
        ],
      });
      right3.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          data: ["转账40万", "贷款10万", "POS机20万", "柜台30万"],
          orient: "vertical",
          textStyle: {
            color: "#999", // 设置图例字体颜色为红色
          },
          left: "75%", // 设置图例居中
          top: "60%", // 设置图例在底部
        },
        series: [
          {
            name: "占比",
            type: "pie",
            radius: ["42%", "60%"],
            center: ["45%", "55%"],
            data: [
              { value: 40, name: "转账40万" },
              { value: 10, name: "贷款10万" },
              { value: 20, name: "POS机20万" },
              { value: 30, name: "柜台30万" },
            ],
            itemStyle: {
              color: function (params) {
                // 定义一个颜色数组
                var colors = ["#00E291", "#00FBFF", "#FF9356", "#1D9DF3"];
                // 返回每个扇区的颜色，可以按照其他逻辑设置颜色
                return colors[params.dataIndex % colors.length];
              },
            },
          },
        ],
      });
      right2.setOption({
        legend: {
          orient: "vertical",
          icon: "circle",
          left: "85%", // 设置图例居中
          top: "53%", // 设置图例在底部
          itemWidth: 12, // 设置图例标记的宽度
          itemHeight: 8, // 设置图例标记的高度

          textStyle: {
            color: "#999999",

          },

        },
            label: {
              formatter: " {per|{c}%}  ",

              rich: {
                b: {
                  color: "#4C5058",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 33,
                },
                per: {
                  color: "#fff",
                },
              },
            },
        series: [
          {
            name: "Nightingale Chart",
            type: "pie",
            radius: [0, 90],
            center: ["45%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: [
              { value: 15, name: "示例" },
              { value: 12, name: "示例1" },
              { value: 20, name: "示例2" },
              { value: 30, name: "示例3" },
              { value: 23, name: "示例4" },
            ],
          },
        ],
      });

      middle2.setOption({
        color: ["#1D9DF3", "#FF9356"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          data: ["使用金额", "使用笔数"],
          textStyle: {
            color: "#999", // 设置图例字体颜色为红色
          },
          left: "75%", // 设置图例居中
          top: "15%", // 设置图例在底部
        },
        grid: {
          //位置
          left: "4%",
          right: "4%",
          top: "27%",

          bottom: "10%",
        },
        xAxis: [
          {
            type: "category",
            data: ["1月", "2月", "3月", "4月", "5月", "6月"],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "万",
            min: 0,
            max: 600,
            interval: 100,
            splitLine: {
              //取消横线
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#777",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "笔数",
            min: 0,
            max: 600,
            interval: 100,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#777",
              },
            },
            axisLabel: {
              formatter: "{value} ",
            },
          },
        ],
        series: [
          {
            name: "使用金额",
            type: "bar",
            data: [120, 200, 150, 80, 70, 510],
            barWidth: "15%",
          },
          {
            name: "使用笔数",
            type: "line",
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data: [120, 250, 360, 110, 150, 550],
          },
        ],
      });

      middle3.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        color: ["#FF9356", "#1D9DF3"],
        legend: {
          data: ["归集金额", "归集笔数"],
          textStyle: {
            color: "#999", // 设置图例字体颜色为红色
          },
          left: "75%", // 设置图例居中
          top: "15%", // 设置图例在底部
        },
        grid: {
          //位置
          left: "4%",
          right: "4%",
          top: "27%",

          bottom: "10%",
        },
        xAxis: [
          {
            type: "category",
            data: ["1月", "2月", "3月", "4月", "5月", "6月"],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "万",
            min: 0,
            max: 600,
            interval: 100,
            splitLine: {
              //取消横线
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#777",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "笔数",
            min: 0,
            max: 600,
            interval: 100,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#777",
              },
            },
            axisLabel: {
              formatter: "{value} ",
            },
          },
        ],
        series: [
          {
            name: "归集金额",
            type: "bar",
            data: [220, 240, 50, 80, 170, 310],
            barWidth: "15%",
          },
          {
            name: "归集笔数",
            type: "line",
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data: [350, 450, 110, 140, 250, 350],
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-left {
      width: 420px;
      margin: 0 20px;
      .left1 {
        background-image: url(../assets/image/maintain/left1.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: flex-start;
        padding-top: 56px;
        flex-wrap: wrap;
        .item {
          width: 180px;
          height: 102px;
          margin-left: 20px;
          margin-bottom: 20px;
          color: #bce1f2;
          .num {
            background: #1d9df3;
            height: 70px;
            color: #ffffff;
            font-size: 16px;
            &.other {
              background: #ff9356;
            }
            font-size: 24px;
            font-weight: bold;
            span {
              opacity: 0.7;
              font-size: 14px;
              padding-left: 5px;
              font-weight: 500;
              margin-bottom: -7px;
            }
          }
          .text {
            height: 32px;
            background: rgba(29, 157, 243, 0.1);
            font-size: 14px;
            &.other {
              background: rgba(255, 147, 86, 0.1);
            }
          }
        }
      }
      .left2 {
        background-image: url(../assets/image/maintain/left2.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-top: 56px;
        .item {
          width: 180px;
          height: 102px;
          margin-left: 20px;
          margin-bottom: 20px;
          background: rgba(29, 157, 243, 0.1);
          position: relative;
          padding-left: 12px;
          .text {
            font-size: 14px;
            color: #bce1f2;
            padding-top: 19px;
            padding-bottom: 8px;
          }
          .num {
            font-size: 24px;
            color: #00fbff;
            span {
              opacity: 0.7;
              font-size: 14px;
              margin-bottom: -7px;
            }
          }
          .icon {
            width: 40px;
            height: 40px;
            border-radius: 50px;

            position: absolute;
            right: 12px;
            top: 20px;
            img {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
      .left3 {
        background-image: url(../assets/image/maintain/left3.png);
        background-size: 100% 100%;
        position: relative;
        .chart {
          height: 300px;
        }
        .data {
          position: absolute;
          z-index: 6666;
          top: 140px;
          left: 57px;
          width: 180px;
          text-align: center;
          color: #fff;
          p {
            font-size: 12px;
            opacity: 0.7;
            margin-bottom: 5px;
          }
          div {
            font-size: 24px;

            span {
              font-size: 12px;

              opacity: 0.7;
            }
          }
        }
        .chart-text {
          position: absolute;
          top: 180px;
          right: 12px;
          & > div {
            width: 140px;
            height: 44px;
            margin-bottom: 12px;
            color: #bce1f2;
            font-size: 12px;
            background-image: url(../assets/image/maintain/left3_1.png);
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px;
            .span {
              font-size: 16px;
              color: #1d9df3;
              span {
                font-size: 12px;
                opacity: 0.7;
              }
            }
            &:nth-child(2) {
              background-image: url(../assets/image/maintain/left3_2.png);
              .span {
                color: #00e291;
              }
            }
          }
        }
      }
    }
    &-middle {
      width: 1000px;
      .session {
        margin-bottom: 20px;
        height: 300px;
      }
      .middle1 {
        background-image: url(../assets/image/maintain/middle1.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;
        text-align: center;
        .item {
          width: 215px;
          height: 134px;
          background-image: url(../assets/image/maintain/middle1_1.png);
          background-size: 100% 100%;
          color: #fff;
          font-size: 14px;
          .num {
            font-size: 32px;
            font-weight: bold;
            padding-top: 30px;
            padding-bottom: 35px;
          }
          span {
            opacity: 0.7;
            font-size: 14px;
            font-weight: 500;
          }
          .text {
            color: #bce1f2;
          }
        }
      }
      .middle2 {
        background-image: url(../assets/image/maintain/middle2.png);
        background-size: 100% 100%;
      }
      .middle3 {
        background-image: url(../assets/image/maintain/middle3.png);
        background-size: 100% 100%;
      }
    }
    &-right {
      width: 420px;
      margin: 0 20px;
      .session {
        margin-bottom: 20px;
        height: 300px;
      }
      .right1 {
        background-image: url(../assets/image/maintain/right1.png);
        background-size: 100% 100%;
        .other {
          font-size: 24px;
          color: #fff;
          text-align: center;
          padding-top: 56px;
          .num {
            margin: 0 auto 4px;
            background-image: url(../assets/image/maintain/right1_1.png);
            background-size: 100% 100%;
            width: 130px;
            height: 84px;

            span {
              opacity: 0.7;
              font-size: 14px;
            }
          }
          .text {
            color: #bce1f2;
            font-size: 16px;
          }
        }
        .items {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 12px;
          .item {
            width: 90px;
            height: 94px;
            padding-top: 18px;
            background-image: url(../assets/image/maintain/right1_2.png);
            background-size: 100% 100%;
            text-align: center;
            .num {
              font-size: 20px;
              font-weight: bold;
              &0 {
                color: #00fbff;
              }
              &1 {
                color: #1d9df3;
              }
              &2 {
                color: #ff9356;
              }
              &3 {
                color: #00e291;
              }
              span {
                font-size: 14px;
                opacity: 0.7;
                font-weight: 500;
              }
            }
            .text {
              color: #bce1f2;
              font-size: 14px;
            }
          }
        }
      }
      .right2 {
        background-image: url(../assets/image/maintain/right2.png);
        background-size: 100% 100%;
      }
      .right3 {
        background-image: url(../assets/image/maintain/right3.png);
        background-size: 100% 100%;
      }
    }
    .session {
      height: 300px;
      margin-bottom: 20px;
    }
  }
}
</style>
