<template>
  <div class="container">
    <div class="content">
      <div class="content-left">
        <div class="session left1">
          <div class="item" v-for="(item, index) in left1" :key="index">
            <div class="text flex-cc">
              <img :src="item.icon" alt="" /> {{ item.text }}
            </div>
            <div class="num flex-cc">{{ item.num }} <span>台</span></div>
          </div>
        </div>

        <div class="session left2">
          <div class="item" v-for="(item, index) in left2" :key="index">
            <div class="text">{{ item.text }}</div>
            <div class="line">
              <div
                class="line-bar"
                :style="{ width: item.value / 10 + 'px' }"
              ></div>
              <div class="value">{{ item.value }}</div>
            </div>
          </div>
        </div>

        <div class="session left3">
          <div class="table">
            <div class="th">
              <div>地址</div>
              <div>小区名称</div>
              <div>物业名称</div>
              <div>排名</div>
            </div>
            <div class="tr" v-for="(item, index) in 9" :key="index">
              <div class="td">XXXXX街道</div>
              <div class="td">XXXXX小区</div>
              <div class="td">XXXXX物业</div>
              <div class="rank">{{ index + 1 }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-middle">
        <div class="session middle1" id="map"></div>
        <div class="session middle2">
          <div class="table">
            <div class="th">
              <div class="aleft"><p>序号</p></div>
              <div>日期</div>
              <div>身份</div>
              <div>姓名</div>
              <div>小区名称</div>
              <div class="aright"><p>内容</p></div>
            </div>
            <div class="tr" v-for="(item, index) in 9" :key="index">
              <div class="td aleft">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="td">2024-05-01</div>
              <div class="td">业主</div>
              <div class="td">李三</div>
              <div class="td">XXXXXx小区</div>
              <div class="td aright"><p>投票表决</p></div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="session right1">
          <div id="chart"></div>
          <div class="text1">78 <span>%</span></div>
          <div class="text2">满意度</div>
          <div class="chart">
            <div>
              <p>参与评价总人数</p>
              <div class="num">43,763 <span>人</span></div>
            </div>
          </div>
        </div>
        <div class="session right2" id="right2"></div>
        <div class="session right3">
          <div class="item" v-for="(item, index) in right2" :key="index">
            <div class="text">{{ item.text }}</div>
            <div class="line">
              <div
                class="line-bar"
                :style="{ width: item.value * 3 + 'px' }"
              ></div>
              <div class="value">{{ item.value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "businessService",
  components: {},
  data() {
    return {
      tabInd: 1,

      left1: [
        {
          num: "2235",
          text: "总户数",
          icon: require("@/assets/image/businessService/left1_1.png"),
        },
        {
          num: "2212",
          text: "绑定用户数",
          icon: require("@/assets/image/businessService/left1_2.png"),
        },
      ],

      left2: [
        {
          text: "物业服务未到位",
          value: 1565,
        },
        {
          text: "公共收费不真实",
          value: 1456,
        },
        {
          text: "绿化管理未到位",
          value: 1358,
        },
        {
          text: "未满意事项名称",
          value: 1246,
        },
        {
          text: "未满意事项名称",
          value: 1125,
        },
        {
          text: "未满意事项名称",
          value: 897,
        },
        {
          text: "未满意事项名称",
          value: 456,
        },
        {
          text: "未满意事项名称",
          value: 356,
        },
      ],

      right2: [
        {
          text: "物业服务态度差",
          value: 75,
        },
        {
          text: "乱收费",
          value: 69,
        },
        {
          text: "设备设施不维护",
          value: 65,
        },
        {
          text: "投诉事项名称",
          value: 57,
        },
        {
          text: "投诉事项名称",
          value: 52,
        },
        {
          text: "投诉事项名称",
          value: 42,
        },
        {
          text: "投诉事项名称",
          value: 22,
        },
        {
          text: "投诉事项名称",
          value: 12,
        },
      ],
    };
  },
  mounted() {
    this.drawLine();
    this.map();
  },
  methods: {
    map() {
      AMapLoader.load({
        key: "d1b569a4b168e12da5a06d9a5aeb6703",
        version: "2.0",
        plugins: [
          "AMap.Geolocation",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.CircleEditor",
        ],
      })
        .then((AMap) => {
          const map = new AMap.Map("map", {
            // eslint-disable-line no-unused-vars
            mapStyle: "amap://styles/grey",
            zoom: 14,
            center: [116.069015, 35.394718],
          });

          // 假设您已经初始化了高德地图API，并且有一个有效的地图实例map

          // 标记的位置数组
          var positions = [
            [116.069015, 35.394718], // 位置1 - 经度, 纬度
            [116.057271, 35.390532], // 位置2
            [116.082514, 35.396637], // 位置3
            // 添加更多位置
          ];

          // 创建标记
          var markers = positions.map(function (position, index) {
            var marker = new AMap.Marker({
              position: position,
              map: map, // 指定将标记添加到哪个地图实例
              title: "标记" + index, // 自定义标题
            });
            // 可以添加更多选项，如icon等
            return marker;
          });

          // 将所有标记添加到地图
          markers.forEach(function (marker) {
            marker.setMap(map);
          });

          // 进行进一步的地图操作...
        })
        .catch((e) => {
          console.log(e);
        });
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      const chart = this.$echarts.init(document.getElementById("chart"));
      const right2 = this.$echarts.init(document.getElementById("right2"));
      chart.setOption({
        series: [
          {
            name: "投票",
            type: "pie",
            radius: ["54%", "75%"],
            center: ["50%", "60%"],
            startAngle: 290,
            endAngle: 360,
            data: [
              { value: 5000, name: "5000次" },
              { value: 1400, name: "1400次" },
            ],
            itemStyle: {
              color: function (params) {
                // 定义一个颜色数组
                var colors = ["#1D9DF3", "#233379"];
                // 返回每个扇区的颜色，可以按照其他逻辑设置颜色
                return colors[params.dataIndex % colors.length];
              },
            },
            label: {
              show: false,
              position: "center",
            },
          },
        ],
      });
      right2.setOption({
        legend: {
          orient: "vertical",
          icon: "circle",
          left: "80%", // 设置图例居中
          top: "38%", // 设置图例在底部
          itemWidth: 12, // 设置图例标记的宽度
          itemHeight: 8, // 设置图例标记的高度

          textStyle: {
            color: "#999", // 设置图例字体颜色为红色
          },
        },
        label: {
          formatter: " {per|{d}%}  ",

          rich: {
            b: {
              color: "#4C5058",
              fontSize: 14,
              fontWeight: "bold",
              lineHeight: 33,
            },
            per: {
              color: "#fff",
            },
          },
        },
        series: [
          {
            name: "Nightingale Chart",
            type: "pie",
            radius: [0, 75],
            center: ["37%", "40%"],
            roseType: "area",
            itemStyle: {
              color: function (params) {
                // 定义一个颜色数组
                var colors = [
                  "#00FBFF",
                  "#FF9356",
                  "#9664FF",
                  "#E964FF",

                ];
                // 返回每个扇区的颜色，可以按照其他逻辑设置颜色
                return colors[params.dataIndex % colors.length];
              },
              borderRadius: 2,
            },
            data: [
              { value: 50, name: "随拍" },
              { value: 35, name: "查看公告" },
              { value: 35, name: "投票表决" },
              { value: 30, name: "办事指南" },
            ],
          },
        ],
      });
    },
    tabSwitch(val) {
      this.tabInd = val;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-left {
      width: 420px;
      margin: 0 20px;
      .session {
        margin-bottom: 20px;
      }
      .left1 {
        background-image: url(../assets/image/businessService/left1.png);
        background-size: 100% 100%;
        padding-top: 56px;
        height: 188px;
        display: flex;
        justify-content: space-around;

        .item {
          margin: 0 auto 20px;
          width: 180px;
          height: 102px;

          color: #bce1f2;
          .num {
            background: rgba(29, 157, 243, 0.1);
            height: 72px;
            color: #ffffff;
            font-size: 24px;
            font-weight: bold;

            span {
              opacity: 0.7;
              font-size: 14px;
              padding-left: 5px;
              font-weight: 500;
              margin-bottom: -7px;
            }
          }
          .text {
            height: 40px;
            background: #1d9df3;
            font-size: 14px;
            img {
              margin-right: 5px;
              width: 16px;
              height: 16px;
            }
          }
          &:nth-child(2) {
            .num {
              background: rgba(0, 226, 145, 0.1);
            }
            .text {
              background: #00e291;
            }
          }
        }
      }
      .left2 {
        background-image: url(../assets/image/businessService/left2.png);
        background-size: 100% 100%;
        padding-top: 56px;
        padding-left: 20px;
        height: 356px;
        .item {
          margin-bottom: 16px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #ffffff;
          .text {
            opacity: 0.7;
            width: 134px;
            text-align: left;
          }
          .line {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .line-bar {
              height: 16px;
              background: #1d9df3;
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
            }
            .value {
              padding-left: 5px;
              opacity: 0.8;
            }
          }
          &:nth-child(1) {
            .text {
              color: #ffeb36;
            }
            .line-bar {
              background: #ffeb36;
            }
            .value {
              color: #ffeb36;
            }
          }
          &:nth-child(2) {
            .text {
              color: #bce1f2;
            }
            .line-bar {
              background: #bce1f2;
            }
            .value {
              color: #bce1f2;
            }
          }
          &:nth-child(3) {
            .text {
              color: #ff9356;
            }
            .line-bar {
              background: #ff9356;
            }
            .value {
              color: #ff9356;
            }
          }
        }
      }
      .left3 {
        height: 356px;
        background-image: url(../assets/image/businessService/left3.png);
        background-size: 100% 100%;

        padding-top: 36px;
        .table {
          line-height: 32px;
          .th {
            height: 32px;
            text-align: center;
            background: rgba(29, 157, 243, 0.3);
            color: #bce1f2;
            font-size: 13px;
            box-shadow: inset 0px -20px 30px 0px rgba(16, 157, 245, 0.2);
            div {
              float: left;
              width: 25%;
            }
          }
          .tr {
            height: 32px;
            font-size: 14px;
            text-align: center;
            div {
              float: left;
              color: rgba(255, 255, 255, 0.8);
              width: 25%;
            }
            &:nth-child(2) {
              .rank {
                color: #ffeb36;
              }
            }
            &:nth-child(3) {
              .rank {
                color: #bce1f2;
              }
            }
            &:nth-child(4) {
              .rank {
                color: #ff9356;
              }
            }
            &:nth-child(3n) {
              width: 420px;
              background: rgba(29, 157, 243, 0.1);
              box-shadow: inset 0px -20px 30px 0px rgba(16, 157, 245, 0.2);
            }
          }
        }
      }
    }
    &-middle {
      width: 1000px;
      .session {
        margin-bottom: 20px;
      }
      .middle1 {
        height: 566px;
        text-align: center;
      }
      .middle2 {
        height: 356px;
        background-image: url(../assets/image/businessService/middle2.png);
        background-size: 100% 100%;
        padding-top: 42px;
        .table {
          line-height: 32px;
          .th {
            height: 32px;
            text-align: center;
            background: rgba(29, 157, 243, 0.3);
            color: #bce1f2;
            font-size: 13px;
            box-shadow: inset 0px -20px 30px 0px rgba(16, 157, 245, 0.2);
            div {
              float: left;
              width: 16.5%;
            }
          }
          .tr {
            height: 32px;
            font-size: 14px;
            text-align: center;
            overflow: hidden;
            div {
              float: left;
              color: rgba(255, 255, 255, 0.8);
              width: 16.5%;
            }
            &:nth-child(2) {
              .rank {
                color: #ffeb36;
              }
            }
            &:nth-child(3) {
              .rank {
                color: #bce1f2;
              }
            }
            &:nth-child(4) {
              .rank {
                color: #ff9356;
              }
            }
            &:nth-child(3n) {
              background: rgba(29, 157, 243, 0.1);
              box-shadow: inset 0px -20px 30px 0px rgba(16, 157, 245, 0.2);
            }
          }
          .aleft {
            text-align: left;
            padding-left: 20px;
            p {
              width: 40px;
              text-align: center;
            }
          }
          .aright {
            text-align: right;
            padding-right: 10px;
            p {
              width: 80px;
              float: right;
              text-align: center;
            }
          }
        }
      }
    }
    &-right {
      width: 420px;
      margin: 0 20px;
      .session {
        margin-bottom: 20px;
        height: 300px;
      }
      .right1 {
        background-image: url(../assets/image/businessService/right1.png);
        background-size: 100% 100%;
        position: relative;
        padding-top: 20px;
        .flex-fs {
          display: flex;
          padding-top: 18px;
          justify-content: flex-start;
          align-items: center;
        }
        .text2 {
          font-size: 14px;
          color: #fff;
          opacity: 0.7;
          position: absolute;
          top: 210px;
          left: 248px;
        }
        .text1 {
          font-size: 22px;
          color: #00fbff;
          position: absolute;
          top: 140px;
          left: 248px;
          span {
            font-size: 14px;
            opacity: 0.7;
          }
        }
        #chart {
          height: 160px;
          margin-left: 140px;
          margin-top: 45px;
          transform: rotate(55deg);
        }
        .chart {
          height: 160px;
          position: absolute;
          top: 20px;
          left: 0;
          right: 0;
          padding: 0 44px 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > div {
            p {
              padding-top: 160px;
              font-size: 14px;
              color: #bce1f2;
              padding-bottom: 12px;
            }
            .num {
              color: #00fbff;
              font-size: 24px;
              span {
                opacity: 0.7;
                font-size: 14px;
              }
            }
          }
          .chart-data {
            position: absolute;
            top: 10px;
            left: 46%;
            p {
              padding-bottom: 4px;
              font-size: 12px;
              padding-top: 62px;
            }
            .num {
              color: #fff;
            }
          }
        }
        .item {
          margin-left: 20px;
          width: 180px;
          height: 102px;

          color: #bce1f2;
          .num {
            background: rgba(0, 226, 145, 0.1);
            height: 72px;
            color: #ffffff;
            font-size: 16px;

            font-size: 24px;
            font-weight: bold;
            span {
              opacity: 0.7;
              font-size: 14px;
              padding-left: 5px;
              font-weight: 500;
              margin-bottom: -7px;
            }
          }
          .text {
            height: 40px;
            color: #fff;
            font-size: 14px;
          }
          &:nth-child(1) {
            .text {
              background: #00e291;
            }
          }
          &:nth-child(2) {
            .text {
              background: #ff7b7b;
            }
          }
        }
      }
      .right2 {
        background-image: url(../assets/image/businessService/right2.png);
        background-size: 100% 100%;
        height: 244px;
        padding: 56px 20px 0;
        font-size: 14px;
        color: #fff;
      }
      .right3 {
        background-image: url(../assets/image/businessService/right3.png);
        background-size: 100% 100%;
        height: 356px;
        padding: 59px 20px 0;
        font-size: 14px;
        color: #fff;
        .item {
          margin-bottom: 18px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .text {
            opacity: 0.7;
            width: 134px;
            text-align: left;
          }
          .line {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .line-bar {
              height: 16px;
              background: #1d9df3;
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
            }
            .value {
              padding-left: 5px;
              opacity: 0.8;
            }
          }
          &:nth-child(1) {
            .text {
              color: #ffeb36;
            }
            .line-bar {
              background: #ffeb36;
            }
            .value {
              color: #ffeb36;
            }
          }
          &:nth-child(2) {
            .text {
              color: #bce1f2;
            }
            .line-bar {
              background: #bce1f2;
            }
            .value {
              color: #bce1f2;
            }
          }
          &:nth-child(3) {
            .text {
              color: #ff9356;
            }
            .line-bar {
              background: #ff9356;
            }
            .value {
              color: #ff9356;
            }
          }
        }
      }
    }
  }
}
</style>
