import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Maintenance from '../views/maintenance.vue'
import Construction from '../views/construction.vue'
import BusinessService from '../views/businessService.vue'
import electronicVoting from '../views/electronicVoting.vue'
import PropertyService from '../views/propertyService.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index
  },
  {
    path: '/maintenance',
    name: '维修资金',
    component: Maintenance
  },
  {
    path: '/construction',
    name: '平安建设',
    component: Construction
  },
  {
    path: '/businessService',
    name: '业务服务管理',
    component: BusinessService
  },
  {
    path: '/electronicVoting',
    name: '电子投票',
    component: electronicVoting
  },
  {
    path: '/propertyService',
    name: '物业服务动态',
    component: PropertyService
  },

]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
