<template>
  <div>
    <div>
      <iframe
        class="iframe"
        src="https://www.yj720.com.cn/t/RAwcyH6aKnI?scene_id=3645"
        frameborder="0"
      ></iframe>
    </div>
    <div class="container">
      <div class="content">
        <div class="content-left">
          <div class="session left1">
            <div class="left-toph"></div>
            <div class="item">
              <div class="nums">{{ leftTop[0].num }}</div>
              <img src="@/assets/image/home/left1-icon1.png" class="imgs" />
              <div class="tags">
                {{ leftTop[0].tag }}
              </div>
            </div>
            <div class="item">
              <div class="nums">{{ leftTop[1].num }}</div>
              <img src="@/assets/image/home/left1-icon2.png" class="imgs" />
              <div class="tags">
                {{ leftTop[1].tag }}
              </div>
            </div>
            <div class="item">
              <div class="nums">{{ leftTop[2].num }}</div>
              <img src="@/assets/image/home/left1-icon3.png" class="imgs" />
              <div class="tags">
                {{ leftTop[2].tag }}
              </div>
            </div>
            <div class="item">
              <div class="nums">{{ leftTop[3].num }}</div>
              <img src="@/assets/image/home/left1-icon4.png" class="imgs" />
              <div class="tags">
                {{ leftTop[3].tag }}
              </div>
            </div>
            <div class="item">
              <div class="nums">{{ leftTop[4].num }}</div>
              <img src="@/assets/image/home/left1-icon5.png" class="imgs" />
              <div class="tags">
                {{ leftTop[4].tag }}
              </div>
            </div>
            <div class="item">
              <div class="nums">{{ leftTop[5].num }}</div>
              <img src="@/assets/image/home/left1-icon6.png" class="imgs" />
              <div class="tags">
                {{ leftTop[5].tag }}
              </div>
            </div>
            <!-- <div class="item" v-for="(item, index) in leftTop" :key="index">
            <div class="nums">{{ item.num }} </div>
            <img src="@/assets/image/home/left1-icon1.png" class="imgs">
            <div class="tags">
              {{ item.tag }} 
            </div>
          </div> -->
          </div>
          <div class="session left2">
            <div class="item2">
              <div class="tagbox">总金额</div>
              <div class="numbox"><span class="numtxt">123</span>亿</div>
            </div>
            <div class="item2">
              <div class="tagbox">使用金额</div>
              <div class="numbox"><span class="numtxt">123</span>亿</div>
            </div>
            <div class="item2">
              <div class="tagbox">总笔数</div>
              <div class="numbox"><span class="numtxt">123</span>笔</div>
            </div>
            <div class="item2">
              <div class="tagbox">使用笔数</div>
              <div class="numbox"><span class="numtxt">123</span>笔</div>
            </div>
          </div>
          <div class="session left3">
            <div class="chart" id="myChart"></div>
            <div class="bili">
              <p>完成率</p>
              <div class="num">68%</div>
            </div>
            <div class="right">
              <div class="item">
                <div class="num">1345个</div>
                <div class="text">需检查项目</div>
              </div>
              <div class="item">
                <div class="num">1445个</div>
                <div class="text">已完成检查</div>
              </div>
            </div>
            <div class="bottom">
              <div class="item flex-sb">
                <div class="flex-fs">
                  <img src="@/assets/image/home/left3-icon1.png" alt="" />
                  常规检查
                </div>
                <span>2次</span>
              </div>
              <div class="item flex-sb">
                <div class="flex-fs">
                  <img
                    src="@/assets/image/home/left3-icon2.png"
                    alt=""
                  />专项检查
                </div>

                <span>6次</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content-middle" @click="homeToastShow = true"></div>
        <div class="content-right">
          <div class="allcont right1">
            <div class="row-top1 font-white">
              <div class="right-tbox">
                较昨日<span style="color: #00fbff; opacity: 0.8"
                  >+12&nbsp;↑</span
                >
              </div>
              <div class="right-tbox">
                较昨日<span style="color: #ff9356; opacity: 0.8"
                  >-3&nbsp;↓</span
                >
              </div>
            </div>
            <div class="row-top2">
              <div class="right-tbox">
                <span class="num" style="color: #00fbff">9,952</span>
              </div>
              <div
                style="height: 40px; background-color: #fff; width: 1px"
              ></div>
              <div class="right-tbox">
                <span class="num" style="color: #ff9356">34,332</span>
              </div>
            </div>
            <div class="row-top1 font-white">
              <div class="right-tbox">参与评价总人数</div>
              <div class="right-tbox">评价总条数</div>
            </div>
          </div>
          <div class="allcont right2">
            <div class="right-cbox">
              <img class="icons" src="@/assets/image/home/right1.png" alt="" />
              <div class="right-centr">
                <div class="right-centr1 font-white text">物业管理区域</div>
                <div class="right-centr2">
                  1231 <span style="font-size: 12px">个</span>
                </div>
              </div>
            </div>
            <div class="right-cbox">
              <img class="icons" src="@/assets/image/home/right2.png" alt="" />
              <div class="right-centr">
                <div class="right-centr1 font-white text">业主绑定率</div>
                <div class="right-centr2">
                  50 <span style="font-size: 12px">%</span>
                </div>
              </div>
            </div>
            <div class="right-cbox">
              <img class="icons" src="@/assets/image/home/right3.png" alt="" />
              <div class="right-centr">
                <div class="right-centr1 font-white text">发起已表决议题</div>
                <div class="right-centr2">
                  1234 <span style="font-size: 12px">个</span>
                </div>
              </div>
            </div>
            <div class="right-cbox">
              <img class="icons" src="@/assets/image/home/right4.png" alt="" />
              <div class="right-centr">
                <div class="right-centr1 font-white text">公示表决</div>
                <div class="right-centr2">
                  1147 <span style="font-size: 12px">个</span>
                </div>
              </div>
            </div>
          </div>
          <div class="allcont right3 font-white">
            <div class="right-head3">
              <div @click="tabSwitch(1)">
                <div class="tab-item" :class="{ active: tabInd == 1 }">
                  物业服务企业
                </div>
                <div :class="{ active_butt: tabInd == 1 }"></div>
              </div>
              <div @click="tabSwitch(2)">
                <div class="tab-item" :class="{ active: tabInd == 2 }">
                  项目负责人
                </div>
                <div :class="{ active_butt: tabInd == 2 }"></div>
              </div>
            </div>
            <div class="right-rowbox right-title">
              <div class="box-no comms">序号</div>
              <div class="box-comp comms">企业名称</div>
              <div class="box-score comms font-white">评价得分</div>
              <div class="box-sort comms">排名</div>
            </div>
            <div
              class="right-rowbox tr"
              v-for="(item, index) in 12"
              :key="index"
            >
              <div class="box-no comms">{{ index + 1 }}</div>
              <div class="box-comp comms">XXXXXXXXXXXX公司</div>
              <div class="box-score comms">{{ 98.42 - (index + 1) * 5 }}</div>
              <div class="box-sort comms">{{ index + 1 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <home-toast @closeToast="closeToast" v-if="homeToastShow"></home-toast>
  </div>
</template>

<script>
import HomeToast from "@/components/homeToast.vue";
export default {
  name: "maintenance",
  components: {
    HomeToast,
  },
  data() {
    return {
      homeToastShow: false,
      tabInd: 1,
      leftTop: [
        {
          id: 1,
          tag: "小区总数",
          icon: "@/assets/image/home/left1-icon1.png",
          num: "197",
        },
        {
          id: 1,
          tag: "总户数",
          icon: "@/assets/image/home/left1-icon2.png",
          num: "1923",
        },
        {
          id: 1,
          tag: "楼栋",
          icon: "@/assets/image/home/left1-icon1.png",
          num: "62",
        },
        {
          id: 1,
          tag: "物业企业",
          icon: "@/assets/image/home/left1-icon1.png",
          num: "84",
        },
        {
          id: 1,
          tag: "业委会",
          icon: "@/assets/image/home/left1-icon1.png",
          num: "11",
        },
        {
          id: 6,
          tag: "项目负责人",
          icon: "@/assets/image/home/left1-icon1.png",
          num: "168",
        },
      ],
    };
  },
  mounted() {
    if (location.href.indexOf("tat=") != -1) {
      this.homeToastShow = true;
    }
    this.drawLine();
  },

  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        series: [
          {
            name: "投票",
            type: "pie",
            radius: ["38%", "52%"],
            center: ["34%", "45%"],
            data: [
              { value: 1400, name: "1400次" },
              { value: 5000, name: "5000次" },
            ],
            itemStyle: {
              color: function (params) {
                // 定义一个颜色数组
                var colors = [" rgba(29,157,243,0.2)", "#1D9DF3"];
                // 返回每个扇区的颜色，可以按照其他逻辑设置颜色
                return colors[params.dataIndex % colors.length];
              },
            },
            label: {
              show: false,
              position: "center",
            },
          },
        ],
      });
    },
    closeToast() {
      this.homeToastShow = false;
    },
    tabSwitch(val) {
      this.tabInd = val;
    },
  },
};
</script>

<style lang="less" scoped>
.iframe {
  position: fixed;
  width: 1920px;
  height: 1080px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}
.container {
  .content {
    display: flex;
    justify-content: flex-start;

    &-left {
      width: 420px;
      margin: 0 20px;
      // background-color: aliceblue;
      position: relative;
      z-index: 9999;
      .left1 {
        height: 300px;
        // background: linear-gradient(90deg,rgba(#164979,100%) 0%,rgba(#164979,10%) 100%);
        background-image: url(../assets/image/home/left-bg1.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: flex-start;
        padding-top: 46px;
        flex-wrap: wrap;
        // .left-toph{margin-top: 20px;width: 100%;height: 9px;}
        .item {
          width: 100px;
          height: 102px;
          margin: 10px 20px;
          flex-wrap: wrap;
          .nums {
            font-size: 24px;
            margin-top: 8px;
            width: 100px;
            color: #fff;
            display: flex;
            justify-content: center; // 内容自适应：上下居中
            // align-items: center;
          }
          .imgs {
            width: 100px;
            margin-top: -14px;
            height: 46px;
          }
          .tags {
            color: #bce1f2;
            font-size: 14px;
            width: 100px;
            display: flex;
            justify-content: center;
          }
        }
      }

      .left2 {
        height: 300px;
        background-image: url(../assets/image/home/left-bg2.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: flex-start;
        padding-top: 51px;
        // padding-bottom: 10px;
        // flex-wrap: wrap;
        flex-direction: column;
        .item2 {
          margin: 5px 20px;
          height: 46px;
          width: 380px;

          background-size: 100% 100%;
          display: flex;
          justify-content: flex-start;
          font-size: 14px;
          &:nth-child(1) {
            background-image: url(@/assets/image/home/left-ic1.png);
          }
          &:nth-child(2) {
            background-image: url(@/assets/image/home/left-ic2.png);
          }
          &:nth-child(3) {
            background-image: url(@/assets/image/home/left-ic3.png);
          }
          &:nth-child(4) {
            background-image: url(@/assets/image/home/left-ic4.png);
          }
          .tagbox {
            width: 200px;
            height: 46px;
            color: #bce1f2;
            display: flex;
            justify-content: flex-start; // 内容自适应：上下居中
            align-items: center;
            padding-left: 78px;
          }
          .numbox {
            width: 180px;
            padding-right: 20px;
            height: 46px;
            display: flex;
            justify-content: flex-end; // 内容自适应：上下居中
            align-items: center;
            color: #fff;
            .numtxt {
              font-size: 20px;
              margin-top: -6px;
              margin-right: 5px;
            }
          }
        }
      }

      .left3 {
        height: 300px;
        background-image: url(../assets/image/home/left-bg3.png);
        background-size: 100% 100%;
        position: relative;
        .chart {
          height: 300px;
        }

        .bili {
          text-align: center;
          position: absolute;
          top: 112px;
          left: 117px;
          color: #fff;
          p {
            font-size: 12px;
            opacity: 0.7;
            padding-bottom: 4px;
          }
          .num {
            font-size: 24px;
          }
        }
        .bottom {
          text-align: center;
          position: absolute;
          bottom: 20px;
          left: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 20px;
          .item {
            width: 180px;
            height: 44px;
            padding-right: 8px;
            margin-right: 20px;
            &:nth-child(1) {
              background-image: url(../assets/image/home/left3_1.png);
              background-size: 100% 100%;
              span {
                color: #1d9df3;
              }
            }
            &:nth-child(2) {
              background-image: url(../assets/image/home/left3_2.png);
              background-size: 100% 100%;
              span {
                color: #ff9356;
              }
            }
          }
          color: #fff;
          font-size: 14px;
          img {
            width: 32px;
            margin-right: 4px;
            height: 32px;
          }
        }
        .right {
          width: 100px;
          text-align: center;
          position: absolute;
          right: 60px;
          top: 80px;
          .item {
            margin-bottom: 20px;
            .num {
              font-size: 14px;
              margin-bottom: 4px;
            }
            &:nth-child(1) {
              .num {
                color: #ff9356;
              }
            }
            &:nth-child(2) {
              .num {
                color: #00fbff;
              }
            }
            .text {
              font-size: 14px;
              color: #fff;
              opacity: 0.7;
            }
          }
        }
      }
    }
    &-middle {
      // position: absolute;
      // left: 0px;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 7777;
      height: 300px;
    }
    &-right {
      position: relative;
      z-index: 9999;
      // background-color: #00fbff;
      width: 420px;
      margin: 0 20px;
      .allcont {
        margin-bottom: 20px;
      }
      .right1 {
        width: 420px;
        height: 170px;
        background-image: url(../assets/image/home/left-bg4.png);
        background-size: 100% 100%;
        padding-top: 36px;

        .row-top1 {
          width: 420px;
          height: 47px;
          display: flex;
          justify-content: flex-start;
        }
        .row-top2 {
          width: 420px;
          height: 40px;
          display: flex;
          justify-content: flex-start;
          font-size: 24px;
        }
        .right-tbox {
          width: 209px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #bce1f2;
          font-size: 14px;
          // background-color: #00fbff;
          .num {
            font-size: 24px;
          }
        }
      }
      .right2 {
        background-image: url(../assets/image/home/left-bg5.png);
        background-size: 100% 100%;
        height: 214px;
        width: 420px;
        padding: 56px 26px 0px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .right-cbox {
          margin-top: 10px;
          height: 60px;
          width: 184px;
          display: flex;
          justify-content: flex-start;
          // background-color: #1d9df3;
          .text {
            font-size: 14px;
            color: #fff;
            opacity: 0.8;
            margin-bottom: 8px;
          }
          .icons {
            height: 51px;
            width: 61px;
          }
          .right-centr {
            padding-left: 10px;
          }
          .right-centr1 {
            width: 100%;
          }
          .right-centr2 {
            font-size: 20px;
            height: 24px;
            color: #00fbff;
          }
        }
        // display: flex;
        // justify-content: flex-start;
      }
      .right3 {
        background-image: url(../assets/image/home/left-bg6.png);
        background-size: 100% 100%;
        height: 516px;
        width: 420px;
        padding-top: 36px;
        display: flex;
        flex-direction: column;
        .right-head3 {
          height: 44px;
          font-size: 16px;
          display: flex;
          flex-direction: row;
          .tab-item {
            margin-left: 16px;
            line-height: 44px;
            text-align: center;
            width: 100px;
          }
          .active {
            color: #00fbff;
            font-size: 16px;
          }
          .active_butt {
            background-color: #00fbff;
            height: 4px;
            width: 40px;
            margin-left: 46px;
            margin-top: -4px;
          }
        }
        .right-rowbox {
          height: 32px;
          width: 420px;
          display: flex;
          flex-direction: row;
        }
        .right-title {
          background-color: rgba(29, 157, 243, 0.5);
        }
        .right-bg2 {
          background-color: rgba(29, 157, 243, 0.1);
        }
        .box-no {
          width: 44px;
        }
        .box-comp {
          width: 188px;
        }
        .box-score {
          color: #00e291;
          width: 108px;
        }
        .box-sort {
          width: 60px;
        }
        .comms {
          padding-left: 10px;
          line-height: 32px;
          text-align: center;
          font-size: 13px;
          color: #bce1f2;
        }

        .tr {
          height: 32px;
          font-size: 14px;
          .box-score {
            color: #00e291;
          }
          &:nth-child(2n) {
            background: rgba(29, 157, 243, 0.1);
            box-shadow: inset 0px -20px 30px 0px rgba(16, 157, 245, 0.2);
          }
          &:nth-child(3) {
            .box-score {
              color: #ffeb36;
            }
            .box-sort {
              color: #ffeb36;
            }
          }
          &:nth-child(4) {
            .box-score {
              color: #bce1f2;
            }
            .box-sort {
              color: #bce1f2;
            }
          }
          &:nth-child(5) {
            .box-score {
              color: #ff9356;
            }
            .box-sort {
              color: #ff9356;
            }
          }
        }
      }
      .font-white {
        color: white;
      }
    }
    .session {
      height: 300px;
      margin-bottom: 20px;
    }
  }
}
</style>
