<template>
  <div class="container">
    <div class="content">
      <div class="content-left">
        <div class="session left1">
          <div class="item" v-for="(item, index) in left1" :key="index">
            <div class="icon">
              <img :src="item.icon" alt="" />
              <p>
                {{ item.text }}
              </p>
            </div>
            <div class="info">
              <div class="info-top">
                任务: <span class="task">{{ item.task }}</span
                >次
              </div>
              <div class="info-bottom">
                完成: <span class="ok">{{ item.ok }}</span
                >次
              </div>
            </div>
          </div>
        </div>

        <div class="session left2">
          <div class="tabs">
            <div
              @click="tabSwitch(1)"
              class="tab-item"
              :class="{ active: tabInd == 1 }"
            >
              公共收支登记
            </div>
            <div
              @click="tabSwitch(2)"
              class="tab-item"
              :class="{ active: tabInd == 2 }"
            >
              物业服务登记
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="info">
                <p>登记小区</p>
                <div class="num">118<span>个</span></div>
              </div>
            </div>
            <div class="item">
              <div class="info">
                <p>公示</p>
                <div class="num">119<span>个</span></div>
              </div>
            </div>
          </div>
          <div id="left2_chart1"></div>
          <div id="left2_chart2"></div>
        </div>
      </div>
      <div class="content-middle">
        <div class="session middle1" id="map">
       
        </div>
        <div class="session middle2">
          <div class="table">
            <div class="th">
              <div class="aleft"><p>序号</p></div>
              <div>日期</div>
              <div>小区名称</div>
              <div class="aright">服务内容</div>
            </div>
            <div class="tr" v-for="(item, index) in 9" :key="index">
              <div class="td aleft">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="td">2024-05-01</div>
              <div class="td">XXXXXx小区</div>
              <div class="td aright">安全检查</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="session right1">
          <div class="item" v-for="(item, index) in right1" :key="index">
            <div class="num">{{ item.num }} <span>个</span></div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
        <div class="session right2">
          <div class="item" v-for="(item, index) in right2" :key="index">
            <div class="text flex-cc">
              <img :src="item.icon" alt="" /> {{ item.text }}
            </div>
            <div class="num flex-cc">{{ item.num }} <span>件</span></div>
          </div>
        </div>
        <div class="session right3">
          <div class="items">
            <div class="item">
              <div class="info">
                <p>发起</p>
                <div class="num">118<span>个</span></div>
              </div>
            </div>
            <div class="item">
              <div class="info">
                
                <p>公示</p>
                <div class="num">119<span>个</span></div>
              </div>
            </div>
          </div>
          <div class="prosess">
            <div class="caption">
              <img src="@/assets/image/propertyService/arrow.png" alt="" />议题
            </div>
            <div
              class="prosess-content"
              v-for="(item, index) in right3"
              :key="index"
            >
              <div class="text">{{ item.text }}</div>
              <div class="line">
                <div class="line-bg">
                  <div
                    class="line-bar"
                    :style="{ width: item.value * 1.5 + 'px' }"
                  ></div>
                </div>

                <div class="value">{{ item.value }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: "businessService",
  components: {},
  data() {
    return {
      tabInd: 1,

      left1: [
        {
          task: "360",
          ok: "129",
          text: "日常巡查",
          icon: require("@/assets/image/propertyService/left1_1.png"),
        },
        {
          task: "212",
          ok: "200",
          text: "专项巡查",
          icon: require("@/assets/image/propertyService/left1_2.png"),
        },
        {
          task: "114",
          ok: "113",
          text: "整改检查",
          icon: require("@/assets/image/propertyService/left1_3.png"),
        },
        {
          task: "129",
          ok: "129",
          text: "结果公告",
          icon: require("@/assets/image/propertyService/left1_4.png"),
        },
      ],

      left2: [
        {
          text: "物业服务未到位",
          value: 1565,
        },
        {
          text: "公共收费不真实",
          value: 1456,
        },
        {
          text: "绿化管理未到位",
          value: 1358,
        },
        {
          text: "未满意事项名称",
          value: 1246,
        },
        {
          text: "未满意事项名称",
          value: 1125,
        },
        {
          text: "未满意事项名称",
          value: 897,
        },
        {
          text: "未满意事项名称",
          value: 456,
        },
        {
          text: "未满意事项名称",
          value: 356,
        },
      ],
      right1: [
        {
          text: "物业企业",
          num: 118,
        },
        {
          text: "业委会",
          num: 3,
        },
        {
          text: "项目经理",
          num: 217,
        },
        {
          text: "业委全成员",
          num: 356,
        },
      ],
      right2: [
        {
          num: "11",
          text: "待处理",
          icon: require("@/assets/image/propertyService/right2_1.png"),
        },
        {
          num: "188",
          text: "已处理",
          icon: require("@/assets/image/propertyService/right2_2.png"),
        },
      ],
      right3: [
        {
          text: "业委会换届",
          value: 118,
        },
        {
          text: "维修资金启动",
          value: 116,
        },
        {
          text: "公共区域建设",
          value: 95,
        },
        {
          text: "续聘",
          value: 80,
        },
      ],
    };
  },
  mounted() {
    this.map()

    this.drawLine();
  },
  methods: {
    map() {
      AMapLoader.load({
        key: "d1b569a4b168e12da5a06d9a5aeb6703",
        version: "2.0",
        plugins: [
          "AMap.Geolocation",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.CircleEditor",
        ],

      })
        .then((AMap) => {
          const map = new AMap.Map("map", { // eslint-disable-line no-unused-vars
    
            zoom: 14,
            center: [	116.069015,35.394718],
          });

// 标记的位置数组
var positions = [
    [116.069015,35.394718], // 位置1 - 经度, 纬度
    [116.057271,35.390532],  // 位置2
    [	116.082514,35.396637]   // 位置3
    // 添加更多位置
];
 
// 创建标记
var markers = positions.map(function(position, index) {
    var marker = new AMap.Marker({
        position: position,
        map: map, // 指定将标记添加到哪个地图实例
        title: '标记' + index // 自定义标题
    });
    // 可以添加更多选项，如icon等
    return marker;
});
 
// 将所有标记添加到地图
markers.forEach(function(marker) {
    marker.setMap(map);
});

          // 进行进一步的地图操作...
        })
        .catch((e) => {
          console.log(e);
        });
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例

      const left2_chart1 = this.$echarts.init(
        document.getElementById("left2_chart1")
      );
      const left2_chart2 = this.$echarts.init(
        document.getElementById("left2_chart2")
      );

      left2_chart2.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "80%", // 设置图例居中
          top: "60%", // 设置图例在底部
          textStyle: {
            color: "#999", // 设置图例字体颜色为红色
          },
        },
        series: [
          {
            name: "投票",
            type: "pie",
            radius: ["42%", "60%"],
            center: ["45%", "70%"],
            data: [
              { value: 50, name: "园林绿化50个" },
              { value: 60, name: "日常卫生60个" },
              { value: 40, name: "暖心服务40个" },
            ],
            itemStyle: {
              color: function (params) {
                // 定义一个颜色数组
                var colors = ["#1D9DF3", "#00E291", "#FFEB36"];
                // 返回每个扇区的颜色，可以按照其他逻辑设置颜色
                return colors[params.dataIndex % colors.length];
              },
            },
          },
        ],
      });

      left2_chart1.setOption({
        legend: {
          orient: "vertical",
          icon: "circle",
          left: "85%", // 设置图例居中
          top: "55%", // 设置图例在底部
          itemWidth: 12, // 设置图例标记的宽度
          itemHeight: 8, // 设置图例标记的高度


          textStyle: {
            color: "#999999",

          },
        },
            label: {
              formatter: " {per|{c}个}  ",

              rich: {
                b: {
                  color: "#4C5058",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 33,
                },
                per: {
                  color: "#fff",
                },
              },
            },
        series: [
          {
            name: "Nightingale Chart",
            type: "pie",
            radius: [0, 75],
            center: ["45%", "60%"],
            roseType: "area",
            itemStyle: {
              color: function (params) {
                // 定义一个颜色数组
                var colors = ["#00FBFF","#FF9356", "#9664FF" , "#E964FF"];
                // 返回每个扇区的颜色，可以按照其他逻辑设置颜色
                return colors[params.dataIndex % colors.length];
              },
            },
            data: [
              { value: 50, name: "场所" },
              { value: 40, name: "道路" },
              { value: 35, name: "车位" },
              { value: 22, name: "空间" },
            ],
          },
        ],
      });
    },
    tabSwitch(val) {
      this.tabInd = val;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-left {
      width: 420px;
      margin: 0 20px;
      .session {
        margin-bottom: 20px;
      }
      .left1 {
        background-image: url(../assets/image/propertyService/left1.png);
        background-size: 100% 100%;
        padding-top: 56px;
        height: 300px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .item {
          margin: 0 auto 20px;
          width: 180px;
          height: 102px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #bce1f2;
          .icon {
            font-size: 14px;
            img {
              width: 64px;
              height: 54px;
              margin-bottom: 8px;
            }
            p {
              font-size: 14px;
            }
          }
          &:nth-child(1) {
            p {
              color: #1d9df3;
            }
          }
          &:nth-child(2) {
            p {
              color: #ff9356;
            }
          }
          &:nth-child(3) {
            p {
              color: #ffeb36;
            }
          }
          &:nth-child(4) {
            p {
              color: #00e291;
            }
          }
          .info {
            padding-top: 19px;
            .info-top {
              background-image: url(../assets/image/propertyService/left1_bg2.png);
              background-size: 100% 100%;
            }
            .info-bottom {
              background-image: url(../assets/image/propertyService/left1_bg1.png);
              background-size: 100% 100%;
            }
            & > div {
              padding-left: 8px;
              width: 104px;
              height: 28px;
              font-size: 12px;
              color: rgba(255, 255, 255, 0.8);
              width: 104px;
              line-height: 28px;

              margin-bottom: 12px;
              .task {
                font-size: 16px;
                padding-right: 4px;
                color: #1d9df3;
              }
              .ok {
                font-size: 16px;
                padding-right: 4px;
                color: #00e291;
              }
            }
          }
        }
      }
      .left2 {
        background-image: url(../assets/image/propertyService/left2.png);
        background-size: 100% 100%;
        padding-top: 48px;

        height: 620px;
        .tabs {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #218eff;
          font-size: 14px;
          text-align: center;
          .tab-item {
            margin-right: 40px;
            cursor: pointer;
            &.active {
              position: relative;
              color: #00fbff;
              &::after {
                content: "";
                position: absolute;
                width: 40px;
                height: 4px;
                background: #00fbff;
                bottom: -15px;
                left: 50%;
                transform: translate(-20px);
              }
            }
          }
        }
        .items {
          display: flex;

          align-items: center;
          padding-top: 30px;
          .item {
            width: 180px;
            height: 85px;
            padding-top: 12px;
            padding-left: 12px;
            margin-left: 20px;
            p {
              font-size: 14px;
              color: rgba(255, 255, 255, 0.7);
              padding-bottom: 8px;
            }
            .num {
              font-size: 24px;
              span {
                font-size: 14px;
                opacity: 0.7;
              }
            }
            &:nth-child(1) {
              background-image: url(../assets/image/propertyService/left2_1.png);
              background-size: 100% 100%;
              .num {
                color: #1d9df3;
              }
            }
            &:nth-child(2) {
              background-image: url(../assets/image/propertyService/left2_2.png);
              background-size: 100% 100%;
              .num {
                color: #ff9356;
              }
            }
          }
        }
        #left2_chart1,
        #left2_chart2 {
          height: 200px;
        }
      }
    }
    &-middle {
      width: 1000px;
      .session {
        margin-bottom: 20px;
      }
      .middle1 {
        height: 566px;
        text-align: center;
      }
      .middle2 {
        height: 356px;
        background-image: url(../assets/image/propertyService/middle2.png);
        background-size: 100% 100%;
        padding-top: 42px;
        .table {
          line-height: 32px;
          .th {
            height: 32px;
            text-align: center;
            background: rgba(29, 157, 243, 0.3);
            color: #bce1f2;
            font-size: 13px;
            box-shadow: inset 0px -20px 30px 0px rgba(16, 157, 245, 0.2);
            div {
              float: left;
              width: 30%;
            }
          }
          .tr {
            height: 32px;
            font-size: 14px;
            text-align: center;
            overflow: hidden;
            div {
              float: left;
              color: rgba(255, 255, 255, 0.8);
              width: 30%;
            }
            &:nth-child(2) {
              .rank {
                color: #ffeb36;
              }
            }
            &:nth-child(3) {
              .rank {
                color: #bce1f2;
              }
            }
            &:nth-child(4) {
              .rank {
                color: #ff9356;
              }
            }
            &:nth-child(3n) {
              background: rgba(29, 157, 243, 0.1);
              box-shadow: inset 0px -20px 30px 0px rgba(16, 157, 245, 0.2);
            }
          }
          .aleft {
            text-align: left;
            width: 20% !important;
            p {
              width: 40px;
              text-align: center;
            }
            padding-left: 20px;
          }
          .aright {
            width: 20% !important;
            text-align: right;
            padding-right: 20px;
          }
        }
      }
    }
    &-right {
      width: 420px;
      margin: 0 20px;
      .session {
        margin-bottom: 20px;
        height: 300px;
      }
      .right1 {
        background-image: url(../assets/image/propertyService/right1.png);
        background-size: 100% 100%;
        position: relative;
        padding-top: 56px;
        display: flex;

        justify-content: space-around;
        flex-wrap: wrap;

        .item {
          margin-left: 20px;
          width: 180px;
          height: 102px;
          background-size: 100% 100%;
          &:nth-child(1) {
            background-image: url(../assets/image/propertyService/right1_1.png);
          }
          &:nth-child(2) {
            background-image: url(../assets/image/propertyService/right1_2.png);
          }
          &:nth-child(3) {
            background-image: url(../assets/image/propertyService/right1_3.png);
          }
          &:nth-child(4) {
            background-image: url(../assets/image/propertyService/right1_4.png);
          }
          text-align: center;
          background-size: 100% 100%;
          color: #bce1f2;
          .num {
            color: #ffffff;

            padding-top: 10px;
            font-size: 24px;
            font-weight: bold;
            span {
              opacity: 0.7;
              font-size: 14px;
              padding-left: 5px;
              font-weight: 500;
              margin-bottom: -7px;
            }
          }
          .text {
            height: 40px;
            color: #fff;
            font-size: 14px;
            padding-top: 10px;
            opacity: 0.7;
          }
        }
      }
      .right2 {
        background-image: url(../assets/image/propertyService/right2.png);
        background-size: 100% 100%;
        height: 188px;
        padding: 56px 10px 0;
        font-size: 14px;
        color: #fff;
        display: flex;
        justify-content: space-around;

        .item {
          margin: 0 auto 20px;
          width: 180px;
          height: 102px;

          color: #bce1f2;
          .num {
            background: rgba(255, 147, 86, 0.1);
            height: 72px;
            color: #ffffff;
            font-size: 24px;
            font-weight: bold;

            span {
              opacity: 0.7;
              font-size: 14px;
              padding-left: 5px;
              font-weight: 500;
              margin-bottom: -7px;
            }
          }
          .text {
            height: 40px;
            color: #fff;
            background: #ff9356;
            font-size: 14px;
            img {
              margin-right: 5px;
              width: 16px;
              height: 16px;
            }
          }
          &:nth-child(2) {
            .num {
              background: rgba(0, 226, 145, 0.1);
            }
            .text {
              background: #00e291;
            }
          }
        }
      }
      .right3 {
        background-image: url(../assets/image/propertyService/right3.png);
        background-size: 100% 100%;
        height: 412px;

        font-size: 14px;
        color: #fff;
        .items {
          padding: 0 10px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding-top: 78px;
          .item {
            width: 180px;
            height: 85px;
            padding-top: 12px;
            padding-left: 12px;
            p {
              font-size: 14px;
              color: rgba(255, 255, 255, 0.7);
              padding-bottom: 8px;
            }
            .num {
              font-size: 24px;
              span {
                font-size: 14px;
                opacity: 0.7;
              }
            }
            &:nth-child(1) {
              background-image: url(../assets/image/propertyService/right3_1.png);
              background-size: 100% 100%;
              .num {
                color: #00fbff;
              }
            }
            &:nth-child(2) {
              background-image: url(../assets/image/propertyService/right3_2.png);
              background-size: 100% 100%;
              .num {
                color: #ff9356;
              }
            }
          }
        }
        .caption {
          padding: 20px;
          img {
            width: 12px;
            height: 12px;
            margin-right: 8px;
          }
          color: #1d9df3;
          font-size: 16px;
        }
        .prosess-content {
          margin-bottom: 25px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .text {
            opacity: 0.7;
            width: 112px;
            padding-left: 20px;
            text-align: left;
          }
          .line {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .line-bg {
              height: 12px;
              width: 260px;
              background: rgba(255, 255, 255, 0.1);
            }
            .line-bar {
              height: 12px;
              background: #1d9df3;
            }
            .value {
              padding-left: 8px;
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}
</style>
